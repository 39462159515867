import React from "react"
import PropTypes from "prop-types"

var videoList = [
  {
    videoLink: "https://www.youtube.com/embed/qC9k2z_0tjg",
    title: "How do we add and subtract capacities?",
  },
  {
    videoLink: "https://www.youtube.com/embed/RybyWcgUTLw",
    title: "Why did agriculture production increase in the Early Villages?",
  },
  {
    videoLink: "https://www.youtube.com/embed/ypUsfAhX5EA",
    title: "How are we dependent on plants?",
  },
  {
    videoLink: "https://www.youtube.com/embed/lWG8pri2Hvw",
    title: "What is speed?",
  },
  {
    videoLink: "https://www.youtube.com/embed/kLk94gKsQQY",
    title: "What are cubes and cube roots?",
  },
  {
    videoLink: "https://www.youtube.com/embed/GMdXsvJQxZg",
    title: "What is an atom made of?",
  },
  {
    videoLink: "https://www.youtube.com/embed/0myshQ6PFEg",
    title: "What is probability?",
  },
  {
    videoLink: "https://www.youtube.com/embed/JGhBepbfKQc",
    title: "How should democracy deal with diversity?",
  },
  {
    videoLink: "https://www.youtube.com/embed/IXSc3FqZUhU",
    title: "How can we measure height from angles?",
  },
]

const VideoData = props => {
  let data = props.data
  return (
    <div>
      <div className="container video_list">
        <div className="row  mt-5">
          {videoList.map((item, key) => {
            return (
              <div
                className="col-xs-12 col-md-6 mx-auto text-center mb-5"
                key={key}
              >
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item rounded-lg"
                    src={item.videoLink}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write;
                    encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen="allowfullscreen"
                  />
                </div>
                <br />
                <p className="video_list_lbl text-center">{item.title}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

VideoData.propTypes = {
  data: PropTypes.string.isRequired,
}

class VideoList extends React.Component {
  propTypes = {
    data: PropTypes.string.isRequired,
  }
  render() {
    return (
      <div>
        <VideoData data={this.props.data} />
      </div>
    )
  }
}

export default VideoList
