import _ from "lodash"

class DatalayerEvents {
  eventSet = new Set()

  constructor() {
    let instance = this
    setInterval(function () {
      let data = instance.eventSet.values().next().value
      if (data) {
        let dataObject = JSON.parse(data)
        if (dataLayer) dataLayer.push(dataObject)
        instance.eventSet.delete(data)
      }
    }, 500)
  }

  pushDataLayer = data => {
    // data.page_url = window.location.href
    // let client_id
    // try {
    //   client_id = ga.getAll()[0].get("clientId")
    // } catch (e) {
    //   console.error(e)
    // }
    // data.client_id = client_id
    this.eventSet.add(JSON.stringify(data))
  }

  leadFormData = (data, status) => {
    let instance = this
    let grade = data.grade ? data.grade : "NA"
    let message = data.message ? data.message : "NA"
    let form_name = data.formName

    if (status == 200) {
      // on success
      instance.pushDataLayer({
        event: "trg_form_submit_success",
        site_section: window.location.href.includes("/plansandpricing")
          ? "plansandpricing"
          : "homepage",
        page_name: window.location.href.includes("/plansandpricing")
          ? "plansandpricing"
          : "homepage",
        // user_type: 'pass the user type for logged in users ,i.e. student,parents',
        // user_ID: 'pass the unique id in encrypted form for every logged in user',
        // customer_ID: 'pass the unique customer ID assigned to logged in customer',
        form_name: form_name,
        grade: data.grade ? "Class " + data.grade.substring(5) : "",
      })
    } else {
      //on fail
      let reason
      if (status == 400) {
        reason = "Bad Request"
      } else if (status == 500) {
        reason = "Internal Server Error"
      } else {
        reason = "Server Time out"
      }

      instance.pushDataLayer({
        event: "trg_form_submit_failed",
        site_section: window.location.href.includes("/plansandpricing")
          ? "plansandpricing"
          : "homepage",
        page_name: window.location.href.includes("/plansandpricing")
          ? "plansandpricing"
          : "homepage",
        form_failed_reason: reason,
        form_name: form_name,
        grade: data.grade ? "Class " + data.grade.substring(5) : "",
      })
    }
  }

  init() {
    let instance = this

    if (window.location.pathname == "/") {
      var page_url = "home"
    } else {
      let page_temp = window.location.pathname.substring(
        1,
        location.pathname.length
      )
      page_url = page_temp.replace("/", "")
    }

    //On Every Page Load -> set
    instance.pushDataLayer({
      event: "trg_page_view",
      site_section: page_url,
      page_name: page_url,
    })

    //header and subheader clicks -> set
    document
      .querySelectorAll(".header_item, .dropdown-item, .logo")
      .forEach(item => {
        item.addEventListener("click", function (event) {
          if (event.target.className == "logo") {
            var header_name = "logo"
          } else if (event.target.className == "nav-link header_item") {
            header_name = event.target.innerText
          } else if (
            event.target.className.substring(0, 13) == "dropdown-item"
          ) {
            if (event.target.href.substring(22, 29) == "support")
              header_name = "support - " + event.target.innerText
            else header_name = "About Studi - " + event.target.innerText
          } else {
            header_name = event.target.innerText
          }
          instance.pushDataLayer({
            event: "trg_header_click",
            site_section: page_url,
            page_name: page_url,
            header_name: header_name,
          })
        })
      })

    //footer click -> set

    document
      .querySelectorAll(".mob_footer_list_item , .footer_list_item")
      .forEach(item => {
        item.addEventListener("click", function (event) {
          if (event.type) {
            instance.pushDataLayer({
              event: "trg_footer_click",
              site_section: page_url,
              page_name: page_url,
              footer_name: event.target.innerText,
            })
          }
        })
      })

    // $(".mob_footer_list_item , .footer_list_item").on("click", function (
    //   event
    // ) {
    //   if (event.type) {
    //     instance.pushDataLayer({
    //       event: "trg_footer_click",
    //       site_section: page_url,
    //       page_name: page_url,
    //       footer_name: event.target.innerText,
    //     })
    //   }
    // })

    //cta click -> set
    document
      .querySelectorAll(
        ".social_icon, .da_app_store_img, .blogs-card-button, .da_app_store_img_footer, #nav_signup_btn, #nav_login_btn, .home-banner-card-button, .cf_singup_btn, #trial_button_white, #trial_button_purple, .trial_text, .modal_click_button, .modal_close_button"
      )
      .forEach(item => {
        item.addEventListener("click", function (event) {
          if (
            event.target.className == "da_app_store_img play_store" ||
            event.target.className == "da_app_store_img_footer play_store"
          ) {
            var cta_name = "Play Store"
            var cta_type = "Icon"
            var click_url =
              "https://play.google.com/store/apps/details?id=com.tce.studi"
          } else if (
            event.target.className == "da_app_store_img" ||
            event.target.className == "da_app_store_img_footer"
          ) {
            cta_name = "App Store"
            cta_type = "Icon"
            click_url = "https://apps.apple.com/in/app/tata-studi/id1532854969"
          } else if (event.target.id == "nav_signup_btn") {
            cta_name = "Sign Up"
            cta_type = "Button"
            click_url = "NA"
          } else if (event.target.className == "blogs-card-button") {
            // console.log("event", event);
            cta_name = "View Plans"
            cta_type = "Button"
            click_url = "/plansandpricing/"
          } else if (event.target.id == "nav_login_btn") {
            cta_name = "Login"
            cta_type = "Button"
            click_url = "NA"
          } else if (
            event.target.id == "trial_button_white" ||
            event.target.id == "trial_button_purple" ||
            event.target.className == "trial_text"
          ) {
            cta_name = "Start your free trial"
            cta_type = "Button"
            click_url = "NA"
          } else if (event.target.className == "modal_click_button") {
            cta_name = "Click Here"
            cta_type = "Button"
            click_url =
              "https://beta.tatastudi.com/authentication/sign-in/(step:enter-username/child)"
          } else if (event.target.className == "modal_close_button") {
            cta_name = "Close"
            cta_type = "Button"
            click_url =
              "https://learn.tatastudi.com/authentication/sign-in/(step:enter-username)"
          } else {
            var href = window.location.href
            cta_name = event.target.host
            cta_type = "Icon"
            click_url = href
          }

          if (event.type) {
            instance.pushDataLayer({
              event: "trg_cta_click",
              site_section: page_url,
              page_name: page_url,
              cta_name: cta_name,
              cta_type: cta_type,
              click_url: click_url,
            })
          }
        })
      })

    //Plan click -> set
    document
      .querySelectorAll(".intermediate_select_btn_div, .pp_price_per_month_lbl")
      .forEach(item => {
        item.addEventListener("click", function (event) {
          if (event.target.id == "monthly_btn") {
            var plan_name = "Monthly"
            var plan_price = document
              .getElementsByClassName(".pp_price_per_month_lbl")[0]
              .text()
              .substring(0, 7)
          } else if (event.target.id == "half-yearly_btn") {
            plan_name = "Half Yearly"
            plan_price = document
              .getElementsByClassName(".pp_price_per_month_lbl")[0]
              .text()
              .substring(7, 15)
          } else if (event.target.id == "yearly_btn") {
            plan_name = "Yearly"
            plan_price = document
              .getElementsByClassName(".pp_price_per_month_lbl")[0]
              .text()
              .substring(15, 23)
          }

          if (event.type) {
            instance.pushDataLayer({
              event: "trg_plan_click",
              site_section: page_url,
              page_name: page_url,
              plan_name: plan_name,
              plan_price: plan_price,
            })
          }
        })
      })

    //Form field fill out interaction -> set
    document
      .querySelectorAll(".cf_get_in_touch_label, .request_call_back")
      .forEach(item => {
        item.addEventListener("change", function (event) {
          if (event.type) {
            instance.pushDataLayer({
              event: "trg_form_field_fillout",
              site_section: page_url,
              page_name: page_url,
              form_name: "Get in Touch",
              field_name: event.target.name,
            })
          }
        })
      })
  }
}
export default DatalayerEvents
