import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import VideoList from "../../components/videolist"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../css/resources.css"
import "../../css/pageBanner.css"
export default class Videos extends Component {
  propTypes = {
    data: PropTypes.string.isRequired,
  }
  render() {
    return (
      <Layout>
        <SEO
          title="Video lessons of core subjects from Class 1-8 CBSE | Tata Studi"
          lang="en"
          description="The most engaging video lessons that have been thoroughly explained in the most simple language for better understanding. Access sample video lesson for free on India's favourite learning platform, Tata Studi"
        />
        <h1 class="displayNone">Videos - Tata Studi</h1>
        <div className="pageBanner blogsPage" style={{ position: "initial" }} />
        <div className="blogs_banner">
          <span>Resources</span>
        </div>

        <div className="div_blogs">
          <span className="blogs_type_lbl font-samsung color-black">
            Our <span className="heading-blue">Latest Videos</span>
          </span>
          <VideoList data={this.props.data.videodata} />
        </div>

        <div
          className="row no-gutters"
          style={{ height: "150px", marginTop: "100px" }}
        >
          <div className="col-sm-8">
            <div className="div_view_plan">
              <span className="blog_pp_lbl">Check out our&nbsp;</span>
              <span className="blog_pp_lbl_last_word">Plans</span>
            </div>
          </div>

          <div className="col-sm-4 div_display_center">
            <Link className="" to="/plansandpricing/">
              <button className="blogs-card-button">View Plans</button>
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    videodata: allNodeVideoBlogs {
      nodes {
        field_video_blogs_title
        field_video_blogs_url
        field_video {
          processed
        }
      }
    }
  }
`
